<template>
  <div>
    <main id="ict-skills">
      <div id="nav">
      </div>
      <section id="page-title" class="container-fluid">
        <div align="left" class="back">
          <router-link to="/">
            <img src="../../src/assets/BackArrow.svg" alt="back-arrow" />
            Back
          </router-link>
        </div>
        <row :gutter="12" class="page-title-container">
          <column :lg="7" :xs="6"
            ><h1 class="title" v-bind:style="colorCode">
              ICT Skills - {{ viewMode }}
            </h1></column
          >
          <column :lg="5" :xs="6" class="toggle-area">
            <h3>Students</h3>
            <div class="wrap">
              <input type="checkbox" id="view-toggle" v-on:click="viewToggle" />
              <label
                v-bind:style="colorCode"
                class="slider"
                for="view-toggle"
              ></label>
            </div>
            <h3>Teachers</h3>
          </column>
        </row>
      </section>
      <section class="ict-select-area">
        <row :gutter="12" class="w-125">
          <column :lg="1" class="ict-select-country"
            ><h3>Select Country</h3></column
          >
          <column :lg="3" class="ict-select-box"
            ><v-select
              :options="countries"
              v-model="selectedCountry"
              class="select-country"
              placeholder="Tanzania"
              :searchable="false"
            ></v-select
          ></column>
          <column :lg="1.2"><h3 class="ict-select-camp">Select Camp</h3></column>
          <column :lg="2.8" class="ict-select-box"
            ><v-select
              :options="camps"
              v-model="selectedCamp"
              class="select-camp"
              placeholder="Nyarugusu"
              :searchable="false"
            ></v-select
          ></column>
          <column :lg="1.2"
            ><h3 class="ict-select-school">Select School</h3></column
          >
          <column :lg="2.8" class="ict-select-box"
            ><v-select
              :options="schools"
              v-model="selectedSchool"
              class="select-school"
              placeholder="Select camp to activate"
              :searchable="false"
            ></v-select
          ></column>
        </row>
      </section>
      <section :gutter="12" class="ict-chart-title-area">
        <row class="ict-chart-title">
          <column :lg="9" :xs="6"
            ><h2 class="ict-sub-title" v-bind:style="colorCode">
              Avg No of ICT skills in Tanzania, Nyarugusu, across schools
            </h2></column
          >
          <column :lg="3" :xs="6">
            <div class="total-skills">
              <span
                ><h1 v-bind:style="colorCode">{{ growthRate }}</h1>
                <h2 v-bind:style="colorCode">skills/students</h2></span
              >
              <h3 v-bind:style="colorCode">after INS (Oct 2020)</h3>
            </div>
            <!-- <div> {{'+38%'}} last 12months</div> -->
          </column>
        </row>
      </section>
      <section class="chart-area">
        <row :gutter="12" class="chart-main">
          <column :lg="8.56" class="ictskills-bar-chart-area">
            <h3>Avg No of ICT skills/student</h3>
            <group-bar-chart
              :chart-data="groupBarChartData"
              :options="options"
            ></group-bar-chart>
            <column :lg="8.56" :xs="12" class="year-select-box">
              <v-select
                :options="compareyears"
                placeholder="Before INS (Oct 2017) vs After INS (Oct 2020)"
                :searchable="false"
                :disabled="true"
              ></v-select>
            </column>
          </column>
          <column :lg="3.44" class="summary-area">
            <row v-for="i in setNoOfRows" v-bind:key="i">
              <column v-for="j in [0, 1]" v-bind:key="i * 2 + j" :lg="6" :xs="6">
                <div
                  v-if="i * 2 + j < summaryBoxData.length"
                  class="summary-grid"
                >
                  <input
                    type="checkbox"
                    class="d-none"
                    v-bind:id="summaryBoxData[i * 2 + j].cssId"
                    v-bind:class="summaryBoxData[i * 2 + j].cssId"
                    v-bind:value="summaryBoxData[i * 2 + j].name"
                    v-model="checkedItems"
                  />
                  <label
                    v-bind:class="summaryBoxData[i * 2 + j].cssId"
                    v-bind:for="summaryBoxData[i * 2 + j].cssId"
                    id="checkbox-label"
                  >
                    <div
                      v-bind:class="summaryBoxData[i * 2 + j].cssId"
                      class="summary-box-data-styled"
                    >
                      <span
                        v-bind:class="summaryBoxData[i * 2 + j].cssId"
                        class="summary-box-data-span"
                        >V</span
                      >
                    </div>
                  </label>
                  <div
                    v-bind:class="summaryBoxData[i * 2 + j].cssId"
                    id="checkbox-text-area"
                  >
                    <h1>{{ summaryBoxData[i * 2 + j].difference }}</h1>
                    <h3>{{ summaryBoxData[i * 2 + j].name }}</h3>
                  </div>
                </div>
              </column>
            </row>
          </column>
        </row>
        <table-for-ICT
          class="table-area"
          v-bind:tableData="tableData"
          v-if="selectedSchool === null"
        ></table-for-ICT>
        <table-for-ICT-skills
          v-if="selectedSchool !== null"
          v-bind:tableData="skillsTableData"
        ></table-for-ICT-skills>
      </section>
    </main>
  </div>
</template>

<script>
import GroupBarChart from "../components/Chart/GroupBarChart";
import TableForICT from "../components/TableForICT";
import {
  getTeacherSchoolSkillData,
  getStudentSchoolSkillData,
  getIctSchoolList,
  getStudentIctSchoolAvg,
  getStudentAvgAcrossSchools,
  getTeacherIctSchoolAvg,
  getTeacherAvgAcrossSchools,
} from "../data/data-provider";
import {
  getTeacherIctRate,
  getStudentIctRate,
  calcDifference,
  getIctTableData,
  getGroupBarChartData,
} from "../data/data-handler";
import {
  getGroupBarChartColorSheme,
  getSkillsGroupBarChartColorSheme,
} from "../data/colour-scheme";

export default {
  components: {
    GroupBarChart,
    TableForICT,
},
  data() {
    return {
      viewMode: "Students",
      colorCode: "",
      growthRate: "",
      countries: ["No more available options"],
      camps: ["No more available options"],
      schools: [],
      summaryBoxData: [],
      checkedItems: [],
      selectedCountry: null,
      selectedCamp: null,
      selectedSchool: null,
      compareyears: ["Before INS (Oct 2017) vs After INS (Oct 2020)"],
      groupBarChartData: {},
      tableData: {},
      skillsTableData: {},
      colorIndex: [],
      options: {
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: { color: "#ffffff" },
              display: true,
              stacked: false,
            },
          ],
          yAxes: [
            {
              display: true,
              position: "left",
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem) {
              if (tooltipItem[0].datasetIndex === 0) {
                return "Before Ins";
              } else if (tooltipItem[0].datasetIndex === 1) {
                return "After Ins";
              }
            },
            label: function (tooltipItem) {
              return parseInt(tooltipItem.value).toFixed(0) + "%";
            },
          },
        },
      },
    };
  },
  mounted() {
    this.showNavBar();
    this.schools = getIctSchoolList();
    this.switchViewMode();
    this.uncheckCheckboxes();
  },
  methods: {
    showNavBar() {
      const navbar = document.getElementById("nav");
      navbar.style.display = "inline";
      const sidebar = document.getElementById("sidebar");
      sidebar.style.display = "block";
    },
    getSkillsTableData(femaleData, maleData, totalData, getAvgAcrossSchool) {
      let femaleBaseYearData = [];
      let femaleEndYearData = [];
      let maleBaseYearData = [];
      let maleEndYearData = [];
      let totalBaseYearData = [];
      let totalEndYearData = [];
      let femaleDiff = [];
      let maleDiff = [];
      let totalDiff = [];

      femaleData.Base.map((el, index) => {
        const skillData = {
          skills: el,
          denominator: femaleData.baseDenominator,
        };
        femaleBaseYearData.push(skillData);
        femaleBaseYearData.push(
          femaleData.baseSkillsPct[index].toFixed(0) + "%"
        );
      });
      femaleData.End.map((el, index) => {
        const skillData = {
          skills: el,
          denominator: femaleData.endDenominator,
        };
        femaleEndYearData.push(skillData);
        femaleEndYearData.push(femaleData.endSkillsPct[index].toFixed(0) + "%");
      });
      maleData.Base.map((el, index) => {
        const skillData = { skills: el, denominator: maleData.baseDenominator };
        maleBaseYearData.push(skillData);
        maleBaseYearData.push(maleData.baseSkillsPct[index].toFixed(0) + "%");
      });
      maleData.End.map((el, index) => {
        const skillData = { skills: el, denominator: maleData.endDenominator };
        maleEndYearData.push(skillData);
        maleEndYearData.push(maleData.endSkillsPct[index].toFixed(0) + "%");
      });
      totalData.Base.map((el, index) => {
        const skillData = {
          skills: el,
          denominator:
            femaleBaseYearData[0].denominator + maleBaseYearData[0].denominator,
        };
        totalBaseYearData.push(skillData);
        totalBaseYearData.push(totalData.baseSkillsPct[index].toFixed(0) + "%");
      });
      totalData.End.map((el, index) => {
        const skillData = {
          skills: el,
          denominator:
            femaleEndYearData[0].denominator + maleEndYearData[0].denominator,
        };
        totalEndYearData.push(skillData);
        totalEndYearData.push(totalData.endSkillsPct[index].toFixed(0) + "%");
      });

      femaleBaseYearData.push(getAvgAcrossSchool("Female", "Base") + "%");
      femaleEndYearData.push(getAvgAcrossSchool("Female", "End") + "%");
      maleBaseYearData.push(getAvgAcrossSchool("Male", "Base") + "%");
      maleEndYearData.push(getAvgAcrossSchool("Male", "End") + "%");
      totalBaseYearData.push(getAvgAcrossSchool("Total", "Base") + "%");
      totalEndYearData.push(getAvgAcrossSchool("Total", "End") + "%");
      calcDifference(femaleData.baseSkillsPct, femaleData.endSkillsPct).map(
        (el) => {
          if (Math.floor(parseInt(el)) > 0) {
            femaleDiff.push("");
            femaleDiff.push("+" + Math.floor(parseInt(el)) + "%");
          } else {
            femaleDiff.push("");
            femaleDiff.push(Math.floor(parseInt(el)) + "%");
          }
        }
      );
      calcDifference(maleData.baseSkillsPct, maleData.endSkillsPct).map(
        (el) => {
          if (Math.floor(parseInt(el)) > 0) {
            maleDiff.push("");
            maleDiff.push("+" + Math.floor(parseInt(el)) + "%");
          } else {
            maleDiff.push("");
            maleDiff.push(Math.floor(parseInt(el)) + "%");
          }
        }
      );
      calcDifference(totalData.baseSkillsPct, totalData.endSkillsPct).map(
        (el) => {
          if (Math.floor(parseInt(el)) > 0) {
            totalDiff.push("");
            totalDiff.push("+" + Math.floor(parseInt(el)) + "%");
          } else {
            totalDiff.push("");
            totalDiff.push(Math.floor(parseInt(el)) + "%");
          }
        }
      );
      femaleDiff.push(
        calcDifference(
          [getAvgAcrossSchool("Female", "Base")],
          [getAvgAcrossSchool("Female", "End")]
        )[0]
      );
      maleDiff.push(
        calcDifference(
          [getAvgAcrossSchool("Male", "Base")],
          [getAvgAcrossSchool("Male", "End")]
        )[0]
      );
      totalDiff.push(
        calcDifference(
          [getAvgAcrossSchool("Total", "Base")],
          [getAvgAcrossSchool("Total", "End")]
        )[0]
      );
      const tableName = this.selectedSchool;
      return {
        femaleBaseYearData,
        femaleEndYearData,
        maleBaseYearData,
        maleEndYearData,
        totalBaseYearData,
        totalEndYearData,
        femaleDiff,
        maleDiff,
        totalDiff,
        tableName,
      };
    },
    setSkillsSummaryBox() {
      const summaryBoxDataArr = [];
      const SKILLS_LIST = [
        "Skill 1",
        "Skill 2",
        "Skill 3",
        "Skill 4",
        "Skill 5",
        "Skill 6",
        "Skill 7",
        "Skill 8",
        "Skill 9",
        "Skill 10",
        "Skill 11",
        "Skill 12",
        "Skill 13",
        "Skill 14",
        "Skill 15",
        "Skill 16",
        "Skill 17",
        "Skill 18",
        "Skill 19",
        "Skill 20",
        "Skill 21",
      ];
      const skillsData = this.skillsTableData.totalDiff
        .filter((el) => el !== "")
        .slice(0, 21);
      SKILLS_LIST.forEach((el, index) => {
        const dictForvFor = {
          name: el,
          difference: skillsData[index],
          cssId: el.replaceAll(" ", ""),
        };
        summaryBoxDataArr.push(dictForvFor);
      });
      return summaryBoxDataArr;
    },
    getSkillsGroupBarChartData(getDataFunc) {
      const SKILLS_LIST = [
        "Skill 1",
        "Skill 2",
        "Skill 3",
        "Skill 4",
        "Skill 5",
        "Skill 6",
        "Skill 7",
        "Skill 8",
        "Skill 9",
        "Skill 10",
        "Skill 11",
        "Skill 12",
        "Skill 13",
        "Skill 14",
        "Skill 15",
        "Skill 16",
        "Skill 17",
        "Skill 18",
        "Skill 19",
        "Skill 20",
        "Skill 21",
      ];
      const baseYearData = getDataFunc(
        this.selectedSchool,
        "Total"
      ).baseSkillsPct;
      const endYearData = getDataFunc(
        this.selectedSchool,
        "Total"
      ).endSkillsPct;
      const dataset = {
        labels: SKILLS_LIST,
        datasets: [
          {
            // label: 'baseYear',
            backgroundColor: getSkillsGroupBarChartColorSheme().opacity,
            barThickness: 10,
            data: baseYearData,
          },
          {
            // label: 'endYear',
            backgroundColor: getSkillsGroupBarChartColorSheme().normal,
            barThickness: 10,
            data: endYearData,
          },
        ],
      };
      return dataset;
    },
    switchViewMode() {
      switch (this.viewMode) {
        case "Students":
          this.colorCode = "color: #8954BA";
          this.growthRate = calcDifference(
            [getStudentAvgAcrossSchools("Total", "Base")],
            [getStudentAvgAcrossSchools("Total", "End")]
          )[0];
          if (this.selectedSchool === null) {
            this.groupBarChartData = this.filterChartData(
              getGroupBarChartData(
                getIctSchoolList(),
                getStudentIctSchoolAvg,
                "Total"
              )
            );
            console.log(
              "los datos son: " + JSON.stringify(this.groupBarChartData)
            );
            this.tableData = getIctTableData(
              getStudentIctRate,
              getStudentAvgAcrossSchools
            );
            this.summaryBoxData = this.setSummaryBoxData();
            this.updateColor(getGroupBarChartColorSheme, this.colorIndex);
          } else {
            this.groupBarChartData = this.filterChartData(
              this.getSkillsGroupBarChartData(
                getStudentSchoolSkillData,
                "Total"
              )
            ); // replace 'Female -> Total' when total data update
            const femaleSkillsData = getStudentSchoolSkillData(
              this.selectedSchool,
              "Female"
            );
            const maleSkillsData = getStudentSchoolSkillData(
              this.selectedSchool,
              "Male"
            );
            const totalSkillsData = getStudentSchoolSkillData(
              this.selectedSchool,
              "Total"
            );
            this.skillsTableData = this.getSkillsTableData(
              femaleSkillsData,
              maleSkillsData,
              totalSkillsData,
              getStudentAvgAcrossSchools
            );
            this.summaryBoxData = this.setSkillsSummaryBox();
            this.updateColor(getSkillsGroupBarChartColorSheme, this.colorIndex);
          }
          break;
        case "Teachers":
          this.colorCode = "color: #0091FF";
          this.growthRate = calcDifference(
            [getTeacherAvgAcrossSchools("Total", "Base")],
            [getTeacherAvgAcrossSchools("Total", "End")]
          )[0];
          if (this.selectedSchool === null) {
            this.groupBarChartData = this.filterChartData(
              getGroupBarChartData(
                getIctSchoolList(),
                getTeacherIctSchoolAvg,
                "Total"
              )
            );
            this.tableData = getIctTableData(
              getTeacherIctRate,
              getTeacherAvgAcrossSchools
            );
            this.summaryBoxData = this.setSummaryBoxData();
            this.updateColor(getGroupBarChartColorSheme, this.colorIndex);
          } else {
            this.groupBarChartData = this.filterChartData(
              this.getSkillsGroupBarChartData(
                getTeacherSchoolSkillData,
                "Total"
              )
            );
            const femaleSkillsData = getTeacherSchoolSkillData(
              this.selectedSchool,
              "Female"
            );
            const maleSkillsData = getTeacherSchoolSkillData(
              this.selectedSchool,
              "Male"
            );
            const totalSkillsData = getTeacherSchoolSkillData(
              this.selectedSchool,
              "Total"
            );
            this.skillsTableData = this.getSkillsTableData(
              femaleSkillsData,
              maleSkillsData,
              totalSkillsData,
              getTeacherAvgAcrossSchools
            );
            this.summaryBoxData = this.setSkillsSummaryBox();
            this.updateColor(getSkillsGroupBarChartColorSheme, this.colorIndex);
          }
          break;
      }
    },
    updateColor(colorScheme, colorIndex) {
      let haveSet = 0;
      colorIndex.sort((a, b) => a - b);
      for (let i = 0; i < this.summaryBoxData.length; i++) {
        const school = this.summaryBoxData[i].cssId;
        const dom = document.getElementsByClassName(`${school}`);
        if (dom.length !== 0 && dom[0].checked === true) {
          const setColor = colorScheme().normal[colorIndex[haveSet]];
          dom[1].style.color = setColor;
          dom[2].style.border = `1px solid ${setColor}`;
          dom[3].style.color = setColor;
          dom[4].style.color = setColor;
          dom[5].style.color = setColor;
          haveSet += 1;
        } else if (dom.length !== 0) {
          dom[1].style.color = "#686868";
          dom[2].style.border = `1px solid ${"#686868"}`;
          dom[3].style.color = "#ffffff";
          dom[4].style.color = "#686868";
          dom[5].style.color = "#212529";
        }
      }
    },
    filterChartData(chartData) {
      if (this.checkedItems.length === 0) {
        return chartData;
      } else {
        const checked = this.checkedItems;
        const colorIndex = [];
        checked.forEach((el) => {
          colorIndex.push(chartData.labels.indexOf(el));
        });
        this.colorIndex = colorIndex;
        const dataIndex = [...Array(chartData.labels.length).keys()].filter(
          (el) => colorIndex.includes(el) === false
        );
        dataIndex.forEach((el) => {
          chartData.datasets[0].data.splice(el, 1, "-");
          chartData.datasets[1].data.splice(el, 1, "-");
          chartData.labels.splice(el, 1, "-");
        });
        return chartData;
      }
    },
    setSummaryBoxData() {
      const summaryBoxDataArr = [];
      for (
        let schoolIndex = 0;
        schoolIndex < this.tableData.columns.length;
        schoolIndex++
      ) {
        const dictForVFor = {
          name: this.tableData.columns[schoolIndex],
          difference: this.tableData.total.difference[schoolIndex],
          cssId: this.tableData.columns[schoolIndex],
        };
        summaryBoxDataArr.push(dictForVFor);
      }
      return summaryBoxDataArr;
    },
    viewToggle() {
      if (this.viewMode === "Students") {
        this.viewMode = "Teachers";
      } else {
        this.viewMode = "Students";
      }
    },
    uncheckCheckboxes() {
      for (let i = 0; i < this.checkedItems.length; i++) {
        const dom = document.getElementsByClassName(
          this.checkedItems[i].replaceAll(" ", "")
        );
        dom.checked = false;
      }
      this.checkedItems = [];
    },
  },
  computed: {
    setNoOfRows() {
      return Array(Math.ceil(this.summaryBoxData.length / 2)).keys();
    },
  },
  watch: {
    viewMode() {
      this.uncheckCheckboxes();
      this.switchViewMode();
    },
    checkedItems() {
      this.switchViewMode();
    },
    selectedSchool(newVal, oldVal) {
      console.log(newVal + " " + oldVal);
      this.uncheckCheckboxes();
      this.switchViewMode();
    },
  },
};
</script>

<styles src="@/assets/styles/views/Ict.css"></styles>